import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const getChannelCompanyAssociations = async () => {
  const data = await client.request(ServiceConstants.GET_CHANNEL_COMPANY_ASSOCIATIONS, {})
    .then((result) => result);

  return data;
};
export default getChannelCompanyAssociations;
