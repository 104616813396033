import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Details from '../pages/Details';
import NotFound from '../pages/NotFound';

export const RoutesConstants = {
  home: '/',
  details: '/details',
  all: '*',
};

const Routes = () => (
  <Switch>
    <Route exact path={RoutesConstants.home} component={Dashboard} />
    <Route exact path={RoutesConstants.details} component={Details} />
    <Route path={RoutesConstants.all} component={NotFound} />
  </Switch>
);

export default Routes;
