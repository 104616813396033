import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const updateLocator = async (enable, channelId) => {
  const serviceURL = enable
    ? ServiceConstants.ENABLE_LOCATOR : ServiceConstants.DISABLE_LOCATOR;

  const data = await client.request(serviceURL, { channel_id: channelId })
    .then((result) => result);

  return data;
};
export default updateLocator;
