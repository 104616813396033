import { JSONRPCClient } from 'json-rpc-2.0';

let jwt;

const url = process.env.REACT_APP_SERVICE_HOST;

export const setToken = (token) => {
  jwt = token;
};

export const clearToken = () => {
  jwt = undefined;
};

export const getToken = () => jwt;

export const getHomePath = () => `/?jwt=${jwt}`;

const client = new JSONRPCClient(
  // eslint-disable-next-line no-undef
  (jsonRPCRequest) => fetch(`${url}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${jwt}`, // Use the passed token
    },
    body: JSON.stringify(jsonRPCRequest),
  }).then((response) => response
    .json()
    // eslint-disable-next-line no-console
    .then((jsonRPCResponse) => client.receive(jsonRPCResponse)), ((err) => console.log('error: ', err))),
);

export default client;
