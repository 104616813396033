import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, Box } from '@material-ui/core';
import { COLORS } from '../../constants/styleConstants';
import GridHeader from './GridHeader';
import GridRow from './GridRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  gridRowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gridRowGap: 6,
    [theme.breakpoints.down('xs')]: {
      gridRowGap: 0,
    },
  },
}));

const Grid = (props) => {
  const classes = useStyles();
  const { columns, data } = props;

  const styles = (theme) => ({
    grid: {
      width: '100%',
      display: 'grid',
      gridColumnGap: 15,
      gridTemplateColumns: columns.map((column) => column.width || '1fr').join(' '),
    },
    gridHeader: {
      padding: '0 12px 16px 12px',
    },
    gridRow: {
      border: `1px solid ${COLORS.mischka}`,
      borderRadius: 7,
      padding: '12px 8px',

      [theme.breakpoints.down('xs')]: {
        border: 0,
        borderRadius: 0,
        borderBottom: `1px solid ${COLORS.mischka}`,
      },
    },
  });

  const StyledGridHeader = withStyles(styles)(GridHeader);
  const StyledGridRow = withStyles(styles)(GridRow);

  return (
    <Box className={classes.root}>
      <StyledGridHeader columns={columns} />
      <Box className={classes.gridRowsContainer}>
        {
          data.map((item, index) => (
            <StyledGridRow index={index} data={item} />
          ))
        }
      </Box>
    </Box>
  );
};

Grid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    width: PropTypes.string,
    labelClassName: PropTypes.string,
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })).isRequired,
};

export default Grid;
