import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Box,
  Container,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SCREEN_SETTINGS } from '../constants/styleConstants';
import AppConstants from '../constants/AppConstants';
import Filters from '../components/filters/Filters';
import BrandCard from '../components/brandCard/BrandCard';
import { Context } from '../context/Context';
import { setToken } from '../services/getClient';
import getChannelCompanyAssociations from '../services/getChannelCompanyAssociations';
import useIsMobile from './useIsMobile';
import Banner from './Banner';

// Creating styles for root container
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0 20px 0',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: 100,
  },
  cardsContainer: {
    display: 'flex',
    gridGap: 10,
    alignSelf: 'center',
  },
  brandCardsContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 20,
    marginTop: 30,

    [theme.breakpoints.up('xs')]: {
      gridGap: 50,
      gridTemplateColumns: '1fr',
    },

    [theme.breakpoints.up('sm')]: {
      gridGap: 50,
      gridTemplateColumns: '1fr 1fr',
    },

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridGap: 30,
      marginTop: 43,
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: 30,
      marginTop: 85,
    },
  },
  link: {
    textDecoration: 'none',
  },
  companyName: {
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    width: '100%',
    position: 'fixed',
    backgroundColor: 'white',
    zIndex: 2,
  },
  headerTextChild: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginLeft: '0px',
    minHeight: '50px',
  },
  firstHeaderText: {
    backgroundColor: '#610c76',
    color: 'white',
  },
  secondHeaderText: {
    backgroundColor: '#709fff',
    color: 'white',
  },
  thirdHeaderText: {
    backgroundColor: '#00ffca',
  },
  fourthHeaderText: {
    backgroundColor: '#ff9933',
  },
  iconHeaderText: {
    height: '25px',
    margin: '1%',
    [theme.breakpoints.down('md')]: {
      height: '20px',
    },
  },
  bannerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  bannerContent: {
    flexGrow: 1,
  },
  closeButton: {
    flexShrink: 0,
    marginLeft: 'auto',
    marginRight: '2rem',
    cursor: 'pointer',
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const {
    channelCompanyAssociations,
    saveChannelCompanyAssociations,
    getDealerStatus,
    getFulfillmentValue,
    dashboardFilter,
    setDashboardFilter,
  } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [showTestRideWarning, setShowTestRideWarning] = useState(false);
  const [showFulfillmentWarning, setShowFulfillmentWarning] = useState(false);
  const [showServiceSchedulingWarning, setShowServiceSchedulingWarning] = useState(false);
  const [showWarrantyWarning, setShowWarrantyWarning] = useState(false);
  const [url, setUrl] = useState('');
  const isMobile = useIsMobile();

  const [
    filteredChannelCompanyAssociations,
    setFilteredChannelCompanyAssociations,
  ] = useState({ ...channelCompanyAssociations });

  const [channelFilters] = React.useState({
    [AppConstants.FILTER_CHANNEL_FULFILLMENT]: false,
    [AppConstants.FILTER_CHANNEL_APPROVED_DEALER]: false,
    [AppConstants.FILTER_CHANNEL_LIVE_DATA]: false,
  });

  const {
    location: { search },
  } = props;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('jwt');

  useEffect(() => {
    setToken(token);

    // fetch data
    async function fetchChannelCompanyAssociations() {
      try {
        const data = await getChannelCompanyAssociations();
        saveChannelCompanyAssociations(data);
        setLoading(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('getChannelCompanyAssociations service failed: ', err);
        setLoading(false);
        setError(true);
      }
    }

    fetchChannelCompanyAssociations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isApproved = (item) => getDealerStatus(item).replace(/\s/g, '').toLowerCase() === 'approveddealer';
    const showBanners = (data) => {
      setUrl(`${process.env.REACT_APP_CORE_URL}dashboard/companies/${data?.company?.id}/shops/`);
      let isTestRideBanner = false;
      let isFulFillmentBanner = false;
      let isServiceSchedulingBanner = false;
      let isWarrantyBanner = false;
      data?.channel_company_associations?.map((item) => {
        if (!isTestRideBanner) {
          isTestRideBanner = item.channel[AppConstants.FILTER_EVENTS_KEY]
            && item[AppConstants.EVENTS_ENABLED]
            && !item.events_dependencies_enabled && isApproved(item);
        }
        if (!isFulFillmentBanner) {
          isFulFillmentBanner = item.channel[AppConstants.FILTER_FULFILLMENT_KEY]
            && item[AppConstants.FULFILLMENT_ENABLED]
            && (data?.is_fulfillment_plus || isApproved(item))
            && !item.fulfillment_dependencies_enabled;
        }
        if (!isServiceSchedulingBanner) {
          isServiceSchedulingBanner = isApproved(item)
            && item.channel[AppConstants.FILTER_SCHEDULER_KEY]
            && item[AppConstants.SCHEDULER_ENABLED] && !item.scheduler_dependencies_enabled;
        }
        if (!isWarrantyBanner) {
          isWarrantyBanner = isApproved(item)
            && item.channel[AppConstants.FILTER_WARRANTY_KEY]
            && item[AppConstants.WARRANTY_ENABLED] && !item.warranty_dependencies_enabled;
        }
        return item;
      });
      if (isTestRideBanner) {
        setShowTestRideWarning(true);
      }
      if (isFulFillmentBanner) {
        setShowFulfillmentWarning(true);
      }
      if (isServiceSchedulingBanner) {
        setShowServiceSchedulingWarning(true);
      }
      if (isWarrantyBanner) {
        setShowWarrantyWarning(true);
      }
    };
    const originalData = { ...channelCompanyAssociations };
    const filterKeys = {
      [AppConstants.FILTER_CHANNEL_FULFILLMENT]:
            AppConstants.FULFILLMENT_ENABLED,
      [AppConstants.FILTER_CHANNEL_APPROVED_DEALER]:
            AppConstants.FULFILLMENT_ENABLED,
      [AppConstants.FILTER_CHANNEL_LIVE_DATA]: AppConstants.LIVE_DATA_ENABLED,
      [AppConstants.FILTER_DEALER_LOCATOR]:
            AppConstants.FILTER_DEALER_LOCATOR_KEY,
      [AppConstants.FILTER_FULFILLMENT]: AppConstants.FILTER_FULFILLMENT_KEY,
      [AppConstants.FILTER_LIVE_DATA]: AppConstants.FILTER_LIVE_DATA_KEY,
    };
    showBanners(originalData);
    const filtersToApply = Object.keys(channelFilters)
      .map((name) => ({
        name: filterKeys[name],
        selected: channelFilters[name],
      }))
      .filter(({ selected }) => selected)
      .map(({ name }) => name);

    if (
      !filtersToApply.length
      && dashboardFilter === AppConstants.FILTER_ALL_BRANDS
    ) {
      return setFilteredChannelCompanyAssociations(originalData);
    }

    // console.log(channelFilters, filter);
    // console.log(filter);
    originalData.channel_company_associations = originalData
      ?.channel_company_associations
      ?.filter((item) => {
        const isFulfillmentEnabled = item[AppConstants.FULFILLMENT_ENABLED];
        const isLiveDataEnabled = item[AppConstants.LIVE_DATA_ENABLED];
        const approved = isApproved(item);
        const isFulfillmentOn = channelFilters[
          AppConstants.FILTER_CHANNEL_FULFILLMENT
        ]
          ? isFulfillmentEnabled
          : true;

        const isLiveDataOn = channelFilters[
          AppConstants.FILTER_CHANNEL_LIVE_DATA
        ]
          ? isLiveDataEnabled && item.channel[AppConstants.FILTER_LIVE_DATA_KEY]
          : true;

        const fulFillmentFilter = dashboardFilter === AppConstants.FILTER_FULFILLMENT
          ? item.channel[AppConstants.FILTER_FULFILLMENT_KEY] && isFulfillmentEnabled
          && item.fulfillment_dependencies_enabled
          && (originalData?.is_fulfillment_plus || approved)
          : true;
        const liveDataFilter = dashboardFilter === AppConstants.FILTER_LIVE_DATA
          ? item.channel[AppConstants.FILTER_LIVE_DATA_KEY] && isLiveDataEnabled
          && item.live_data_dependencies_enabled && approved
          : true;

        let connectWith = false;

        AppConstants.FEATURES_KEY.forEach((feature) => {
          const featureIsFulfillment = feature.name === 'fulfillment';
          if (featureIsFulfillment && originalData?.is_fulfillment_plus) {
            if (item.channel[feature.available] && item[feature.enabled]
              && item[feature.dependencies_enabled]) {
              connectWith = true;
            }
          } else if (isApproved(item) && item.channel[feature.available] && item[feature.enabled]
              && item[feature.dependencies_enabled]) {
            connectWith = true;
          }
          if (item.channel[feature.available] && item[feature.enabled]) {
            connectWith = true;
          }
        });

        const isConnectedWith = dashboardFilter === AppConstants.CONNECTED_DEALERS
          ? connectWith : true;

        const isNotConnectedWith = dashboardFilter === AppConstants.NOT_CONNECTED_DEALERS
          ? !connectWith : true;

        const dealerLocatorFilter = dashboardFilter === AppConstants.FILTER_DEALER_LOCATOR
          ? item.channel[AppConstants.FILTER_DEALER_LOCATOR_KEY]
          && item.locator_enabled && item.locator_dependencies_enabled
          && approved
          : true;

        const isServiceAppointmentsFilter = dashboardFilter === AppConstants.FILTER_SERVICE
          ? item.channel[AppConstants.FILTER_SCHEDULER_KEY] && item[AppConstants.SCHEDULER_ENABLED]
          : true;

        const isTestRideFilter = dashboardFilter === AppConstants.FILTER_TEST_RIDE
          ? item.channel[AppConstants.FILTER_EVENTS_KEY] && item[AppConstants.EVENTS_ENABLED]
          : true;

        return (
          isFulfillmentOn
                    && fulFillmentFilter
                    && isConnectedWith
                    && isNotConnectedWith
                    && isLiveDataOn
                    && liveDataFilter
                    && dealerLocatorFilter
                    && isServiceAppointmentsFilter
                    && isTestRideFilter
        );
      });

    return setFilteredChannelCompanyAssociations(originalData);
  }, [channelFilters, channelCompanyAssociations, getDealerStatus, dashboardFilter]);

  const noData = !loading
    && !filteredChannelCompanyAssociations?.channel_company_associations?.length;
  const loadedWithData = filteredChannelCompanyAssociations
            && filteredChannelCompanyAssociations.channel_company_associations
            && filteredChannelCompanyAssociations.channel_company_associations
              .length >= 0;

  let boxMarginTop = 85;
  const showTestRideCount = showTestRideWarning ? 1 : 0;
  const showFulfillmentCount = showServiceSchedulingWarning ? 1 : 0;
  const showServiceSchedulingCount = showFulfillmentWarning ? 1 : 0;
  const showWarrantyCount = showWarrantyWarning ? 1 : 0;
  const totalBannersCount = showTestRideCount + showFulfillmentCount + showServiceSchedulingCount
    + showWarrantyCount;

  if (totalBannersCount) {
    const eachBannerMarginTop = isMobile ? 95 : 75;
    boxMarginTop = 85 + (totalBannersCount * eachBannerMarginTop);
  }

  const closeBanner = (bannerType) => {
    switch (bannerType) {
      case 'testRide':
        setShowTestRideWarning(false);
        break;
      case 'fulfillment':
        setShowFulfillmentWarning(false);
        break;
      case 'serviceScheduling':
        setShowServiceSchedulingWarning(false);
        break;
      case 'warranty':
        setShowWarrantyWarning(false);
        break;
      default:
        break;
    }
  };

  return (
    <Container maxWidth={false} className={classes.root}>
      <div className={classes.headerText}>
        {showTestRideWarning && (
          <div className={classes.bannerContent}>
            <div className={classes.bannerContainer}>
              <Banner
                text={`Your account is set to receive test ride bookings from one or more Brands
                  but no shop locations have events turned on. Turn on events for
                  your shop location(s) at your`}
                imageClass={classes.iconHeaderText}
                headerClass={`${classes.headerTextChild} ${classes.firstHeaderText}`}
                urlStyle={{ color: '#fff', marginLeft: '5px' }}
                url={url}
                closeComponent={(
                  <span
                    onClick={() => closeBanner('testRide')}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        closeBanner('testRide');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    className={classes.closeButton}
                  >
                    <CloseIcon />
                  </span>
                )}
              />
            </div>
          </div>
        )}

        {showFulfillmentWarning && (
          <div className={classes.bannerContent}>
            <div className={classes.bannerContainer}>
              <Banner
                text={
                  `Your account is set to receive fulfillment orders from one or more Brands
                  but no shop locations have fulfillment turned on. Turn on fulfillment for
                  your shop location(s) at your`
                }
                imageClass={classes.iconHeaderText}
                headerClass={`${classes.headerTextChild} ${classes.secondHeaderText} `}
                urlStyle={{ color: '#fff', marginLeft: '5px' }}
                url={url}
                onClose={() => closeBanner('fulfillment')}
                closeComponent={(
                  <span
                    onClick={() => closeBanner('fulfillment')}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        closeBanner('fulfillment');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    className={classes.closeButton}
                  >
                    <CloseIcon />
                  </span>
                )}
              />
            </div>
          </div>
        )}

        {showServiceSchedulingWarning && (
          <div className={classes.bannerContent}>
            <div className={classes.bannerContainer}>
              <Banner
                text={`Your account is set to receive service appointments from one or more Brands
                  but no shop locations have service turned on. Turn on service
                  for your shop location(s) at your`}
                imageClass={classes.iconHeaderText}
                headerClass={`${classes.headerTextChild} ${classes.thirdHeaderText} `}
                urlStyle={{ color: '#000', marginLeft: '5px' }}
                url={url}
                closeComponent={(
                  <span
                    onClick={() => closeBanner('serviceScheduling')}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        closeBanner('serviceScheduling');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    className={classes.closeButton}
                  >
                    <CloseIcon />
                  </span>
                )}
              />
            </div>
          </div>
        )}

        {showWarrantyWarning && (
          <div className={classes.bannerContent}>
            <div className={classes.bannerContainer}>
              <Banner
                text={`Your account is set to receive warranty appointments from one or more brands 
                  but no shop locations have warranties turned on. Turn on on warranties for your shop location(s) at your`}
                imageClass={classes.iconHeaderText}
                headerClass={`${classes.headerTextChild} ${classes.fourthHeaderText} `}
                urlStyle={{ color: '#000', marginLeft: '5px' }}
                url={url}
                closeComponent={(
                  <span
                    onClick={() => closeBanner('warranty')}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        closeBanner('warranty');
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    className={classes.closeButton}
                  >
                    <CloseIcon />
                  </span>
                )}
              />
            </div>
          </div>
        )}
        <Filters value={dashboardFilter} setValue={setDashboardFilter} />
      </div>
      <Container
        maxWidth={SCREEN_SETTINGS.default_viewport_width}
        className={classes.contentContainer}
      >
        {loading && !loadedWithData && (
          <Box display="flex" m={2} justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {noData && (
          <Box display="flex" m={15} justifyContent="center" style={{ marginTop: `${boxMarginTop}px` }}>
            <Typography variant="subtitle2">{error ? 'Token has expired or is invalid. Please refresh the page.' : 'No matching brands found…' }</Typography>
          </Box>
        )}
        <Box className={classes.brandCardsContainer} style={{ marginTop: `${boxMarginTop}px` }}>
          {loadedWithData
            && filteredChannelCompanyAssociations.channel_company_associations.map(
              (item, index) => {
                const brandNameWithoutSpace = item.channel.name
                  .replace(/\s/g, '')
                  .toLowerCase();
                return (
                  <Link
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${brandNameWithoutSpace}_${index}`}
                    to={`/details?id=${item.channel.id}&jwt=${token}`}
                    className={classes.link}
                    scroll={false}
                  >
                    <BrandCard
                      key={`brand_card_${index + 1}`}
                      logo={item.channel.logo_url}
                      name={item.channel.name}
                      company={filteredChannelCompanyAssociations?.company}
                      retailerRelationship={item.channel.retailer_relationship}
                      isFulfillmentPlus={filteredChannelCompanyAssociations?.is_fulfillment_plus}
                      status={getDealerStatus(
                        item,
                        filteredChannelCompanyAssociations.is_fulfillment_plus,
                      )}
                      liveData={{
                        available: item.channel.live_data_available,
                        enabled:
                          item.live_data_enabled
                          && filteredChannelCompanyAssociations.live_data_active,
                        dependencies_enabled: item.live_data_dependencies_enabled,
                        shops: item.live_data_shops,
                      }}
                      storeLocator={{
                        available: item.channel.locator_available,
                        dependencies_enabled: item.locator_dependencies_enabled,
                        enabled: item.locator_enabled,
                      }}
                      marketRetailer={{
                        available: item.channel.preferred_market_retailer_available,
                        dependencies_enabled: item.preferred_market_dependencies_enabled,
                        enabled: item.preferred_market_retailer_enabled,
                      }}
                      rideBooking={{
                        available: item.channel.events_available,
                        dependencies_enabled: item.events_dependencies_enabled,
                        enabled: item.events_enabled,
                      }}
                      fulfillment={{
                        available: item.channel.fulfillment_available,
                        enabled: item.fulfillment_enabled,
                        dependencies_enabled: item.fulfillment_dependencies_enabled,
                        value: getFulfillmentValue(item.channel_compensation),
                      }}
                      scheduler={{
                        available: item.channel.scheduler_available,
                        dependencies_enabled: item.scheduler_dependencies_enabled,
                        enabled: item.scheduler_enabled,
                      }}
                      warranty={{
                        available: item.channel.warranty_available,
                        dependencies_enabled: item.warranty_dependencies_enabled,
                        enabled: item.warranty_enabled,
                      }}
                    />
                  </Link>
                );
              },
            )}
        </Box>
      </Container>
    </Container>
  );
};

Dashboard.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Dashboard;
