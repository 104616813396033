import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const updateFulfillment = async (enable, channelId) => {
  const serviceURL = enable
    ? ServiceConstants.ENABLE_FULFILLMENT : ServiceConstants.DISABLE_FULFILLMENT;

  const data = await client.request(serviceURL, { channel_id: channelId })
    .then((result) => result);

  return data;
};
export default updateFulfillment;
