export default {
  FILTER_DEALER_LOCATOR_KEY: 'locator_available',
  FILTER_FULFILLMENT_KEY: 'fulfillment_available',
  FILTER_LIVE_DATA_KEY: 'live_data_available',
  FILTER_SCHEDULER_KEY: 'scheduler_available',
  FILTER_EVENTS_KEY: 'events_available',
  FILTER_WARRANTY_KEY: 'warranty_available',
  ON_OFF_SWITCH: 'onOffSwitch',
  DISABLED: 'disabled',
  ENABLED: 'enabled',
  LEFT: 'left',
  RIGHT: 'right',
  // CHANNEL FILTERS
  FILTER_CHANNEL_FULFILLMENT: 'filterChannelFulfillment',
  FILTER_CHANNEL_APPROVED_DEALER: 'filterChannelApprovedDealer',
  FILTER_CHANNEL_LIVE_DATA: 'filterChannelLiveData',
  CONNECTED_DEALERS: 'connectedDealers',
  NOT_CONNECTED_DEALERS: 'notConnectedDealers',

  // FILTERS
  FILTER_ALL_BRANDS: 'allBrands',
  FILTER_DEALER_LOCATOR: 'dealerLocator',
  FILTER_FULFILLMENT: 'fulfillment',
  FILTER_SERVICE: 'service',
  FILTER_TEST_RIDE: 'testRide',
  FILTER_LIVE_DATA: 'liveData',
  FILTER_WARRANTY: 'warranty',

  FULFILLMENT_ENABLED: 'fulfillment_enabled',
  LIVE_DATA_ENABLED: 'live_data_enabled',
  SCHEDULER_ENABLED: 'scheduler_enabled',
  EVENTS_ENABLED: 'events_enabled',
  WARRANTY_ENABLED: 'warranty_enabled',

  FEATURES_KEY: [{ name: 'test ride', available: 'events_available', enabled: 'events_enabled', dependencies_enabled: 'events_dependencies_enabled' },
    { name: 'fulfillment', available: 'fulfillment_available', enabled: 'fulfillment_enabled', dependencies_enabled: 'fulfillment_dependencies_enabled' },
    { name: 'live data', available: 'live_data_available', enabled: 'live_data_enabled', dependencies_enabled: 'live_data_dependencies_enabled' },
    { name: 'preferred market retailer', available: 'preferred_market_retailer_available', enabled: 'preferred_market_retailer_enabled', dependencies_enabled: 'preferred_market_dependencies_enabled' },
    { name: 'locator', available: 'locator_available', enabled: 'locator_enabled', dependencies_enabled: 'locator_dependencies_enabled' },
    { name: 'scheduler', available: 'scheduler_available', enabled: 'scheduler_enabled', dependencies_enabled: 'scheduler_dependencies_enabled' },
    { name: 'warranty', available: 'warranty_available', enabled: 'warranty_enabled', dependencies_enabled: 'warranty_dependencies_enabled' }],
};
