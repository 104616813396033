/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Typography,
  Container,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { COLORS, FONT_SIZE, SCREEN_SETTINGS } from '../../constants/styleConstants';
import MATERIAL_UI_CONSTANTS from '../../constants/materialUIConstants';
import AppConstants from '../../constants/AppConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: COLORS.white,
    minHeight: 65,
    padding: '15px 0',
    boxShadow: '0 2px 8px -3px rgba(124, 124, 124, 0.2)',
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      overflow: 'initial',
    },
  },

  fieldset: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 'medium',
  },

  label: {
    minWidth: 300,
    border: '1px solid #d8d8d8',
    paddingLeft: '15px',
    borderRadius: '50px',
    fontWeight: 500,
    fontSize: '14px',

    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '&.Mui-focused > .MuiInputBase-input': {
      backgroundColor: 'white',
      borderRadius: '50px',
    },
  },
}));

const Filters = (props) => {
  const classes = useStyles();
  const { value, setValue } = props;
  const options = [
    { value: 'allBrands', label: 'All Brands' },
    { value: 'connectedDealers', label: 'Brands my company is connected with' },
    { value: 'notConnectedDealers', label: 'Brands my company is not connected with' },
    { value: 'fulfillment', label: 'Brands my company can receive fulfillment orders from' },
    { value: 'service', label: ' Brands my company can receive service appointments from' },
    { value: 'testRide', label: 'Brands my company can receive test ride bookings from' },
  ];

  const handleChange = (event) => {
    const selectedFilter = event.target.value;
    setValue(selectedFilter);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth={SCREEN_SETTINGS.default_viewport_width}>
        <FormControl component="fieldset" className={classes.fieldset}>
          <Select
            className={classes.label}
            value={value}
            onChange={handleChange}
          >
            {
              options.map((o) => (
                <MenuItem style={{ whiteSpace: 'initial', fontSize: '14px', fontWeight: 'normal' }} value={o.value}>{o.label}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Container>
    </Box>
  );
};

Filters.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default Filters;
