import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
    width: 'auto',
    height: 100,
  },
  img: {
    width: 'auto',
    height: 100,
    marginRight: 23,
    borderRadius: 5,
  },
}));

const LogoSection = (props) => {
  const classes = useStyles();
  const { img, name, url } = props;

  const hideImg = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.style.display = 'none';
  };

  return (
    <Box className={classes.root}>
      {img && <img src={img} alt={name} className={classes.img} onError={hideImg} />}
      <Typography
        variant="h1"
        title={name}
      >
        { url ? (
          <Link href={url} underline="always" target="_blank" rel="noreferrer">
            {name}
          </Link>
        ) : name }
      </Typography>
    </Box>
  );
};

LogoSection.propTypes = {
  img: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
};

LogoSection.defaultProps = {
  img: '',
  name: '',
  url: '',
};

export default LogoSection;
