import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as Sentry from '@sentry/react';
import { ContextProvider } from '../context/Context';
import theme from '../styles/theme';
import Routes from '../routes/Routes';

function FallbackComponent() {
  return (
    <div>An error has occurred</div>
  );
}
const App = () => (
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <Router>
      <ThemeProvider theme={theme}>
        <ContextProvider>
          <CssBaseline />
          <Routes />
        </ContextProvider>
      </ThemeProvider>
    </Router>
  </Sentry.ErrorBoundary>
);

export default App;
