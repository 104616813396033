import React from 'react';

function Circle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <circle
            cx="12"
            cy="12"
            r="10"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            data-name="Circle"
          />
        </g>
      </g>
    </svg>
  );
}

export default Circle;
