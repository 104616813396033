import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import TickIcon from '../icons/TickIcon';
import CrossIcon from '../icons/CrossICon';
import AppConstants from '../../constants/AppConstants';
import Circle from '../icons/circle';
import Exclamation from '../icons/Exclamation';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridColumnGap: 11,
    minHeight: 15,

    [theme.breakpoints.down('xs')]: {
      minHeight: 11,
      gridColumnGap: 9,
    },

    '& .MuiSvgIcon-root': {
      width: 15,
      height: 15,

      [theme.breakpoints.down('xs')]: {
        width: 11,
        height: 11,
      },
    },
  },
  gridColumnsLeft: {
    gridTemplateColumns: '15px 1fr',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '11px 1fr',
    },
  },
  gridColumnsRight: {
    gridTemplateColumns: '1fr 15px',

    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr 11px',
    },
  },
  content: {
    opacity: 0.8,
  },
  flagged: {
    color: 'red',
  },
}));

const ContentListItem = (props) => {
  const classes = useStyles();
  const { icon, content, direction, isFlagged = false } = props;
  const noWrap = true;

  const getIcon = () => {
    if (icon === 'tick') {
      return <TickIcon />;
    } if (icon === 'cross') {
      return <CrossIcon />;
    } if (icon === 'circle') {
      return <Circle />;
    } if (icon === 'exclamation') {
      return <Exclamation />;
    }

    return null;
  };

  const getLabel = () => (
    <Typography
      noWrap={noWrap}
      variant="body2"
      className={`${classes.content} ${isFlagged ? classes.flagged : ''}`}
      title={content}
    >
      {content}
    </Typography>
  );

  const getRenderElement = () => {
    if (direction === AppConstants.RIGHT) {
      return (
        <>
          {getLabel()}
          {getIcon()}
        </>
      );
    }

    return (
      <>
        {getIcon()}
        {getLabel()}
      </>
    );
  };

  return (
    <Box
      className={`${classes.root} ${direction === AppConstants.RIGHT ? classes.gridColumnsRight : classes.gridColumnsLeft}`}
    >
      { getRenderElement() }
    </Box>
  );
};

ContentListItem.propTypes = {
  icon: PropTypes.string,
  content: PropTypes.string,
  direction: PropTypes.string,
  isFlagged: PropTypes.bool,
};

ContentListItem.defaultProps = {
  icon: '',
  content: '',
  direction: AppConstants.LEFT,
  isFlagged: false,
};

export default ContentListItem;
