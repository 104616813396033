import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const updateFulfillmentByTag = async (enable, channelId, tag) => {
  const toggleURL = enable
    ? ServiceConstants.ENABLE_FULFILLMENT_BY_TAG : ServiceConstants.DISABLE_FULFILLMENT_BY_TAG;

  const data = await client.request(toggleURL, { channel_id: channelId, tag })
    .then((result) => result);

  return data;
};

export default updateFulfillmentByTag;
