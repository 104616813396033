import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const getBadges = async (channelId) => {
  const data = await client.request(ServiceConstants.GET_BADGES, {
    channel_id: channelId,
  });

  return data;
};
export default getBadges;
