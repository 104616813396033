import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TickIcon = () => (
  <SvgIcon width="15" height="15" viewBox="0 0 15 15">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g transform="translate(-232.000000, -762.000000) translate(197.000000, 398.000000) translate(35.000000, 313.000000) translate(0.000000, 51.000000)">
                <circle cx="7.5" cy="7.5" r="7.5" fill="#00DB73" />
                <path stroke="#FFF" d="M3.529 8.272L5.946 10.588 11.471 5.294" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default TickIcon;
