import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { COLORS } from '../../constants/styleConstants';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 8,
    backgroundColor: COLORS.concrete,
  },
  line: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
    backgroundColor: COLORS.mischka,
    height: 1,
  },
}));

const Separator = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.line} />
      <Box className={classes.root} />
    </>
  );
};

export default Separator;
