import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const getLocations = async (channelId) => {
  const data = await client.request(ServiceConstants.GET_LOCATIONS, {
    channel_id: channelId,
  });

  return data;
};
export default getLocations;
