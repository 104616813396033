import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { COLORS, FONT_SIZE } from '../../constants/styleConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 26,
    width: '100%',
  },
  detailsText: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    color: COLORS.black,
    padding: '3px 23px',
    display: 'inline-block',
  },
}));

const WarrantySection = (props) => {
  const classes = useStyles();
  const { details, children, isEnabled } = props;
  if (!isEnabled) return null;

  return (
    <>
      <Box className={classes.root}>
        <Typography variant="h2">
          Warranty
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.detailsText}>
          {details}
          {children}
        </Typography>
      </Box>
    </>
  );
};

WarrantySection.propTypes = {
  details: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  isEnabled: PropTypes.string.isRequired,
};

export default WarrantySection;
