import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Banner = (props) => {
  const { text, headerClass, imageClass, url, urlStyle, closeComponent } = props;
  const [expanded, setExpanded] = useState(false);
  const [width, setWidth] = useState(0);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const handleResize = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setWidth]);

  const screenSize = width > 940 ? 5.5 : 7;
  const calculateWidth = (width - 100) / screenSize;
  const isExpandViewEnabled = width > 550;
  const showText = expanded || isExpandViewEnabled ? text : `${text.slice(0, calculateWidth)}...`;

  return (
    <div className={headerClass}>
      <img src="/images/exclamation_icon.svg" alt="exclamation_icon" className={imageClass} />
      <p style={{ width: '100%', paddingLeft: '5px', paddingRight: '5px' }}>
        {showText}
        {
          (expanded || isExpandViewEnabled) && (
            <a href={url} target="_blank" rel="noreferrer" style={urlStyle}>
              shops page.
            </a>
          )
        }
        {width < 551 && (
          <textbox style={{ textDecoration: 'underline', cursor: 'pointer', marginRight: '15px', marginLeft: '10px', justifyContent: 'end' }} onClick={toggleExpanded}>
            {expanded ? 'Read less' : 'Read more'}
          </textbox>
        )}
      </p>
      {closeComponent}
    </div>
  );
};
Banner.propTypes = {
  text: PropTypes.string,
  headerClass: PropTypes.string,
  imageClass: PropTypes.string,
  url: PropTypes.string,
  urlStyle: PropTypes.object,
  closeComponent: PropTypes.element.isRequired,
};
Banner.defaultProps = {
  text: '',
  headerClass: '',
  imageClass: '',
  url: '',
  urlStyle: {},
};
export default Banner;
