import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import { COLORS, FONTS, FONT_SIZE, FONT_STYLE } from '../constants/styleConstants';

const pxToRem = (size) => `${(size / 16) * 1}rem`;

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: COLORS.black,
    },
    secondary: {
      main: COLORS.theme_secondary,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: COLORS.page_background,
          fontFamily: FONTS.primary,
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: FONTS.primary,
        fontStretch: FONT_STYLE.normal,
        fontStyle: FONT_STYLE.normal,
        fontWeight: FONT_STYLE.normal,
        lineHeight: 1,
        letterSpacing: FONT_STYLE.normal,
        textAlign: 'center',
        fontSize: pxToRem(FONT_SIZE.font_14),
        padding: '8px 13px 8px 14px',
        height: 30,
        borderRadius: 15,
      },
      containedPrimary: {
        fontWeight: 600,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: COLORS.theme_primary,
        },
      },
      containedSecondary: {
        border: `solid 1px ${COLORS.mischka}`,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: COLORS.theme_secondary,
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: FONTS.primary,
      },
    },
  },
});

// defining font styles for typography variants
theme.typography = {
  h1: {
    color: COLORS.black,
    fontSize: pxToRem(FONT_SIZE.font_24),
    fontFamily: FONTS.primary,
    fontStyle: FONT_STYLE.normal,
    fontWeight: 600,
    letterSpacing: FONT_STYLE.normal,
    lineHeight: FONT_STYLE.normal,
    fontStretch: FONT_STYLE.normal,
    // defining font-size for all the devices except mobile
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(FONT_SIZE.font_24),
    },
  },
  h2: {
    color: COLORS.heading,
    fontSize: pxToRem(FONT_SIZE.font_20),
    fontFamily: FONTS.primary,
    fontStyle: FONT_STYLE.normal,
    fontWeight: 600,
    letterSpacing: FONT_STYLE.normal,
    lineHeight: FONT_STYLE.normal,
    fontStretch: FONT_STYLE.normal,
    // defining font-size for all the devices except mobile
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(FONT_SIZE.font_20),
    },
  },
  subtitle1: {
    color: COLORS.sub_heading,
    fontSize: pxToRem(FONT_SIZE.font_14),
    fontFamily: FONTS.primary,
    fontStyle: FONT_STYLE.normal,
    fontWeight: FONT_STYLE.normal,
    letterSpacing: FONT_STYLE.normal,
    lineHeight: FONT_STYLE.normal,
    fontStretch: FONT_STYLE.normal,
    // defining font-size for all the devices except mobile
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(FONT_SIZE.font_16),
    },
  },
  subtitle2: {
    color: COLORS.sub_heading,
    fontSize: pxToRem(FONT_SIZE.font_12),
    fontFamily: FONTS.primary,
    fontStyle: FONT_STYLE.normal,
    fontWeight: FONT_STYLE.normal,
    letterSpacing: FONT_STYLE.normal,
    lineHeight: FONT_STYLE.normal,
    fontStretch: FONT_STYLE.normal,
    // defining font-size for all the devices except mobile
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(FONT_SIZE.font_14),
    },
  },
  body1: {
    color: COLORS.black,
    fontSize: pxToRem(FONT_SIZE.font_13),
    fontFamily: FONTS.primary,
    fontStyle: FONT_STYLE.normal,
    fontWeight: FONT_STYLE.bold,
    letterSpacing: FONT_STYLE.normal,
    lineHeight: FONT_STYLE.normal,
    fontStretch: FONT_STYLE.normal,
    // defining font-size for all the devices except mobile
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(FONT_SIZE.font_13),
    },
  },
  body2: {
    color: COLORS.black,
    fontSize: pxToRem(FONT_SIZE.font_10),
    fontFamily: FONTS.primary,
    fontStyle: FONT_STYLE.normal,
    fontWeight: FONT_STYLE.normal,
    letterSpacing: FONT_STYLE.normal,
    lineHeight: FONT_STYLE.normal,
    fontStretch: FONT_STYLE.normal,
    // defining font-size for all the devices except mobile
    [theme.breakpoints.up('sm')]: {
      fontSize: pxToRem(FONT_SIZE.font_12),
    },
  },
  pxToRem,
};

export default theme;
