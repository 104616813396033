import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const GridHeader = (props) => {
  const noWrap = true;
  const { columns, classes } = props;

  return (
    <Box className={`${classes.grid} ${classes.gridHeader}`}>
      {
        columns.map((item, index) => (
          <Box key={`header_${index + 1}`} id={`header_${index + 1}`} className={item.wrapperClassName || ''}>
            <Typography
              noWrap={noWrap}
              className={item.labelClassName || ''}
            >
              {item.label}
            </Typography>
          </Box>
        ))
      }

    </Box>
  );
};

GridHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
  })),
  classes: PropTypes.shape({
    grid: PropTypes.string,
    gridHeader: PropTypes.string,
  }).isRequired,
};

GridHeader.defaultProps = {
  columns: [],
};

export default GridHeader;
