import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorCode: {
    marginRight: 20,
    padding: '10px 23px 10px 0',
    borderRight: '1px solid rgba(0, 0, 0,.3)',
  },
}));

const NotFound = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography variant="h1" className={classes.errorCode}>404</Typography>
      <Typography variant="subtitle1">Not Found</Typography>
    </Box>
  );
};

export default NotFound;
