import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Container, Button } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import Separator from '../components/separator/Separator';
import { COLORS, FONT_SIZE, FONT_STYLE, SCREEN_SETTINGS } from '../constants/styleConstants';
import LogoSection from '../components/detailsSections/LogoSection';
import BrandAssociationSection from '../components/detailsSections/BrandAssociationSection';
import FulfillmentSection from '../components/detailsSections/FulfillmentSection';
import { Context } from '../context/Context';
import updateLiveData from '../services/updateLiveData';
import updateFulfillment from '../services/updateFulfillment';
import updateLocator from '../services/updateLocator';
import updateScheduler from '../services/updateScheduler';
import { setToken } from '../services/getClient';
import BadgesSection from '../components/detailsSections/BadgesSection';
import PreferredMarketRetailerSection from '../components/detailsSections/PreferredMarketRetailerSection';
import getChannelCompanyAssociations from '../services/getChannelCompanyAssociations';
import LiveData from '../components/detailsSections/LiveData';
import getLocations from '../services/getLocations';
import updateWarranty from '../services/updateWarranty';
import WarrantySection from '../components/detailsSections/WarrantySection';
import MATERIAL_UI_CONSTANTS from '../constants/materialUIConstants';
import DocumentsSection from '../components/detailsSections/DocumentsSection';
import getChannelDocuments from '../services/getDocuments';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0 20px 0',
  },
  contentContainerHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 0,
    position: 'relative',
    paddingBottom: 30,
  },
  img: {
    width: 'auto',
    height: 50,
    marginRight: 23,
    borderRadius: 5,
  },
  backButton: {
    position: 'absolute',
    left: 10,
    top: 3,
  },
  gridRowsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    border: `1px solid ${COLORS.mischka}`,
    borderRadius: 7,
    padding: '0 1.5%',
    marginBottom: '1%',
  },
  roundedButton: {
    borderRadius: '15px',
    overflow: 'hidden',
    padding: '10px 16px',
    fontSize: '17px',
    fontWeight: 'bold',
  },
  requestDealerStatusLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    fontWeight: FONT_STYLE.bold,
    color: COLORS.black,
    backgroundColor: COLORS.supernova,
  },
}));

const Details = (props) => {
  const classes = useStyles();
  const {
    location: { search },
  } = props;

  const searchParams = new URLSearchParams(search);
  const brandId = searchParams.get('id');
  const token = searchParams.get('jwt');

  const { getChannelCompanyAssociationItemById, channelCompanyAssociations,
    saveChannelCompanyAssociations } = useContext(Context);
  const brandData = useRef({});
  const [loading, setLoading] = useState(true);
  const [showFulfillmentProductToggles,
    setShowFulfillmentProductToggles] = useState(false);
  const [showWarrantyButton, setShowWarrantyButton] = useState(false);
  const [showDocumentsButton, setShowDocumentsButton] = useState(false);

  useEffect(() => {
    (async () => {
      async function getChannelAssociationItem() {
        try {
          if (Object.keys(channelCompanyAssociations).length > 0) {
            brandData.current = getChannelCompanyAssociationItemById(brandId);
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
        }
      }
      setShowFulfillmentProductToggles(brandData.current.fulfillment_enabled);
      getChannelAssociationItem();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelCompanyAssociations]);

  useEffect(() => {
    (async () => {
      setToken(token);
      async function fetchChannelCompanyAssociations() {
        try {
          const data = await getChannelCompanyAssociations();
          saveChannelCompanyAssociations(data);
        } catch (err) {
          setLoading(false);
        }
      }
      setShowFulfillmentProductToggles(brandData.current.fulfillment_enabled);
      fetchChannelCompanyAssociations();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const [locations, setLocations] = useState([]);

  const {
    channel,
    channel_compensation: channelCompensation,
    live_data_enabled: liveDataEnabled,
    fulfillment_enabled: fulfillmentEnabled,
    is_dealer: isDealer,
    locator_enabled: locatorEnabled,
    scheduler_enabled: schedulerEnabled,
    events_enabled: eventsEnabled,
    preferred_market_retailer_enabled: preferredMarketRetailerEnabled,
    preferred_market_dependencies_enabled: preferredMarketRetailerDependenciesEnabled,
    preferred_market_retailer_compensation_rules: preferredMarketRetailerCompensationRules,
    preferred_market_retailer_badge: preferredMarketRetailerBadge,
    live_data_shops: liveDataShops,
    live_data_pos: liveDataPos,
    warranty_enabled: warrantyEnabled,
  } = brandData.current;

  const {
    company,
    is_admin: isAdmin,
    is_shop_active: isShopActive,
    is_fulfillment_plus: isFulfillmentPlus,
    add_brand_for_fulfillment_url: addBrandForFulfillmentURL,
    live_data_active: isLiveDataActive,
  } = channelCompanyAssociations;

  const handleLiveDataSwitchChange = (value) => {
    updateLiveData(value, channel.id);
  };

  const handleFulfillmentSwitchChange = (value) => {
    updateFulfillment(value, channel.id);
    setShowFulfillmentProductToggles(value);
  };

  const handleLocatorSwitchChange = (value) => {
    updateLocator(value, channel.id);
  };

  const handleSchedulerSwitchChange = (value) => {
    updateScheduler(value, channel.id);
  };

  const handleEventsSwitchChange = (value) => {
    updateScheduler(value, channel.id, true);
  };

  brandData.current = getChannelCompanyAssociationItemById(brandId);

  const filteredChannelCompensation = () => {
    if (brandData.current.channel_compensation) {
      return brandData.current.channel_compensation.filter(
        (item) => item.compensation_value && item.compensation_value !== '0',
      );
    }
    return [];
  };

  const hasFulfillmentCompensation = () => filteredChannelCompensation()?.length > 0;

  const hasPmrCompensation = () => preferredMarketRetailerCompensationRules
    && preferredMarketRetailerCompensationRules.length > 0;

  const hasCompensation = () => hasFulfillmentCompensation() || hasPmrCompensation();

  const handleWarrantySwitchChange = (value) => {
    updateWarranty(value, channel.id, true);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    (async () => {
      if (channel) {
        const { shops: locationsData } = await getLocations(channel.id);
        setLocations(locationsData);
        setShowWarrantyButton(channel.warranty_available || channel.unsupported_warranty_available);
        const { documents: documentData } = await getChannelDocuments(channel.uuid);
        setShowDocumentsButton(documentData?.length > 0);
      }
    })();
  }, [channel]);

  return !loading && Object.keys(channelCompanyAssociations).length > 0 && (
    <Container maxWidth={false} className={classes.root}>
      <Container
        maxWidth={SCREEN_SETTINGS.default_viewport_width}
        className={classes.contentContainerHeader}
      >
        <Button className={classes.backButton} onClick={history.goBack}>
          <ArrowBackIosIcon fontSize="small" />
          <span>Back</span>
        </Button>
        <LogoSection img="" name={channel?.name} url={channel?.website_url} />
      </Container>
      <Separator />

      {/* Brand Association */}
      <Container
        maxWidth={SCREEN_SETTINGS.default_viewport_width}
        className={classes.contentContainer}
      >
        <BrandAssociationSection
          data={brandData.current}
          isAdmin={isAdmin}
          isEnterprise={channel?.is_enterprise}
          isShopActive={isShopActive}
          isFulfillmentPlus={isFulfillmentPlus}
          addBrandForFulfillmentURL={addBrandForFulfillmentURL}
          locatorEnabled={locatorEnabled}
          locatorAvailable={channel?.locator_available && company.locator_available}
          preferredMarketRetailerEnabled={preferredMarketRetailerEnabled}
          preferredMarketRetailerDependenciesEnabled={preferredMarketRetailerDependenciesEnabled}
          preferredMarketRetailerAvailable={channel?.preferred_market_retailer_available}
          schedulerEnabled={schedulerEnabled}
          schedulerAvailable={channel?.scheduler_available && company.scheduler_available}
          eventsEnabled={eventsEnabled}
          eventsAvailable={channel?.events_available}
          isDealer={isDealer}
          liveDataState={liveDataEnabled}
          liveDataAvailable={channel?.live_data_available && company.live_data_available}
          onLiveDataSwitchChange={handleLiveDataSwitchChange}
          fulfillment={channel?.fulfillment_available && company.fulfillment_available}
          fulfillmentState={fulfillmentEnabled}
          isLiveDataActive={isLiveDataActive}
          warrantyAvailable={channel.warranty_available}
          warrantyEnabled={warrantyEnabled}
          onFulfillmentSwitchChange={handleFulfillmentSwitchChange}
          onLocatorSwitchChange={handleLocatorSwitchChange}
          onSchedulerSwitchChange={handleSchedulerSwitchChange}
          onEventsSwitchChange={handleEventsSwitchChange}
          onWarrantySwitchChange={handleWarrantySwitchChange}
        />
      </Container>
      <Separator />
      {showDocumentsButton && (
        <>
          <div>
            <Container
              maxWidth={SCREEN_SETTINGS.default_viewport_width}
              className={classes.contentContainer}
            >
              <DocumentsSection isEnabled={showDocumentsButton}>
                <Button
                  type="button"
                  variant={MATERIAL_UI_CONSTANTS.contained}
                  className={classes.requestDealerStatusLabel}
                  onClick={() => {
                    window.open(`${process.env.REACT_APP_DASHBOARD_URL}/public/documents/${brandData.current.channel.uuid}`, '_blank');
                  }}
                >
                  See Brand Documents
                </Button>
              </DocumentsSection>
            </Container>
          </div>
          <Separator />
        </>
      )}
      {hasCompensation() && (
        <Container
          maxWidth={SCREEN_SETTINGS.default_viewport_width}
          className={classes.contentContainer}
        >
          {hasFulfillmentCompensation() && (
            <FulfillmentSection
              data={channelCompensation}
              brandData={brandData.current}
              isAdmin={isAdmin}
              showFulfillmentProductToggles={showFulfillmentProductToggles}
            />
          )}
        </Container>
      )}

      {preferredMarketRetailerCompensationRules?.length > 0 && (
        <div>
          <Separator />
          <Container
            maxWidth={SCREEN_SETTINGS.default_viewport_width}
            className={classes.contentContainer}
          >
            <PreferredMarketRetailerSection
              data={preferredMarketRetailerCompensationRules}
              brandData={brandData.current}
              preferredMarketRetailerBadge={preferredMarketRetailerBadge}
            />
            <div style={{ marginTop: '20px' }}>
              * Per Product in Order
            </div>
          </Container>
        </div>
      )}

      {liveDataShops?.length > 0 && (
        <div>
          <Separator />
          <Container
            maxWidth={SCREEN_SETTINGS.default_viewport_width}
            className={classes.contentContainer}
          >

            <LiveData
              data={liveDataShops}
              locations={locations}
              liveDataPos={liveDataPos}
            />

          </Container>
        </div>
      )}
      <div>
        <Separator />
        <Container
          maxWidth={SCREEN_SETTINGS.default_viewport_width}
          className={classes.contentContainer}
        >
          <WarrantySection isEnabled={showWarrantyButton}>
            <Button
              type="button"
              variant={MATERIAL_UI_CONSTANTS.contained}
              className={classes.requestDealerStatusLabel}
              onClick={() => {
                window.open(`${process.env.REACT_APP_CORE_URL}/warranty-compensation/${brandData.current.channel.uuid}`, '_blank');
              }}
            >
              See Warranty Services and Compensation
            </Button>
          </WarrantySection>
        </Container>
      </div>
      <Separator />
      <Container
        maxWidth={SCREEN_SETTINGS.default_viewport_width}
        className={classes.contentContainer}
      >
        <BadgesSection
          channelId={channel?.id}
          retailerRelationship={channel?.retailer_relationship}
        />
      </Container>
    </Container>
  );
};

Details.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Details;
