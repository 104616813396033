import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const updateLiveData = async (enable, channelId) => {
  const serviceURL = enable
    ? ServiceConstants.ENABLE_LIVE_DATA : ServiceConstants.DISABLE_LIVE_DATA;

  const data = await client.request(serviceURL, { channel_id: channelId })
    .then((result) => result);

  return data;
};
export default updateLiveData;
