import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '../grid/Grid';
import { COLORS, FONT_SIZE, FONT_STYLE } from '../../constants/styleConstants';
import titleCase from '../../utils.js';
import updateFulfillmentByTag from '../../services/updateFulfillmentByTag';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 26,
    width: '100%',
  },
  gridContainer: {
    marginTop: 25,
    width: '100%',
  },
  gridHeaderLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_12),
    fontWeight: FONT_STYLE.bold,
    color: COLORS.black,
  },
  gridHeadingWrapper: {
    textAlign: 'center',
  },
  fulfillmentTypeRowLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    color: COLORS.black,
    padding: '3px 23px',
    borderRadius: 5,
    backgroundColor: 'transparent',
    display: 'inline-block',
  },
  dealerCompRowLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_15),
    fontWeight: FONT_STYLE.bold,
    padding: 0,
    marginLeft: 0,
    color: COLORS.black,
    opacity: 0.7,
    borderRadius: 0,
    display: 'inline-block',

    [theme.breakpoints.down('xs')]: {
      color: COLORS.funGreen,
      backgroundColor: COLORS.polar,
      borderRadius: 14,
      padding: '3px 14px',
      marginLeft: '-14px',
    },
  },
  nonDealerCompRowLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_15),
    fontWeight: FONT_STYLE.bold,
    color: COLORS.scienceBlue,
    borderRadius: 7,
    backgroundColor: COLORS.selago,
    padding: '3px 14px',
    marginLeft: '-14px',
    display: 'inline-block',
  },
  gridRowLabelWrapper: {
    textAlign: 'center',
  },
}));

const FulfillmentSection = (props) => {
  const classes = useStyles();
  const { data, isAdmin, brandData, showFulfillmentProductToggles } = props;
  const noWrap = true;
  const [gridData, setGridData] = useState([]);

  const gridColumns = [
    {
      label: 'Fulfillment type',
      labelClassName: classes.gridHeaderLabel,
      width: '0.8fr',
    },
    {
      label: '',
      labelClassName: classes.gridHeaderLabel,
      width: '0.2fr',
    },
    {
      label: `${titleCase(brandData.channel.retailer_relationship)} comp`,
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
    {
      label: `Non ${titleCase(brandData.channel.retailer_relationship)} comp`,
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
    {
      label: 'Additional mobile comp',
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
  ];

  const convertToGridDataFormat = (channelCompensation) => channelCompensation.map(
    (item) => ([
      item.name ? {
        label: item.name,
        labelClassName: classes.fulfillmentTypeRowLabel,
      } : {
        label: '',
        labelClassName: classes.fulfillmentTypeRowLabel,
      },
      {
        label: '',
        disabled: !isAdmin,
        switchState: showFulfillmentProductToggles ? !item.exclude : undefined,
        handleSwitchChange() {
          if (brandData?.fulfillment_enabled) {
            // eslint-disable-next-line no-param-reassign
            item.exclude = !item.exclude;
            updateFulfillmentByTag(!item.exclude, brandData.channel.id, item.tag);
            setGridData(convertToGridDataFormat(data));
          }
        },
      },
      item.dealer_compensation_value ? {
        label: `$${item.dealer_compensation_value}`,
        labelClassName: brandData.is_dealer ? classes.nonDealerCompRowLabel : '',
        wrapperClassName: classes.gridRowLabelWrapper,
      } : {
        label: '',
        labelClassName: classes.dealerCompRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
      (item.compensation_value && item.compensation_value !== '0') ? {
        label: `$${item.compensation_value}`,
        labelClassName: !brandData.is_dealer ? classes.nonDealerCompRowLabel : '',
        wrapperClassName: classes.gridRowLabelWrapper,
      } : {
        label: '',
        labelClassName: classes.dealerCompRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
      (item.mobile_compensation_value_up_charge && item.mobile_compensation_value_up_charge !== '0') ? {
        label: `+ $${item.mobile_compensation_value_up_charge}`,
        labelClassName: '',
        wrapperClassName: classes.gridRowLabelWrapper,
      } : {
        label: '',
        labelClassName: classes.dealerCompRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
    ]),
  );

  useEffect(() => {
    setGridData(convertToGridDataFormat(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showFulfillmentProductToggles]);

  return (
    <>
      <Box className={classes.root}>
        <Typography
          noWrap={noWrap}
          variant="h2"
        >
          Fulfillment
        </Typography>
      </Box>
      <Box className={classes.gridContainer}>
        <Grid columns={gridColumns} data={gridData} />
      </Box>
    </>
  );
};

FulfillmentSection.propTypes = {
  data: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  brandData: PropTypes.string.isRequired,
  showFulfillmentProductToggles: PropTypes.bool.isRequired,
};

export default FulfillmentSection;
