import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const requestDealerStatus = async (channelId) => {
  const data = await client.request(
    ServiceConstants.REQUEST_DEALER_STATUS, { channel_id: channelId },
  )
    .then((result) => result);

  return data;
};
export default requestDealerStatus;
