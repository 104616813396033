import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const optOut = async (channelRequestId, companyId, message) => {
  const serviceURL = ServiceConstants.UPDATE_DEALER;

  const data = await client.request(serviceURL, { action: 'revoke_dealer_status_update',
    channel_request_id: channelRequestId,
    message,
    company_id: companyId })
    .then((result) => result);

  return data;
};
export default optOut;
