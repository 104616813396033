import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const updateScheduler = async (enable, channelId, events = false) => {
  const serviceURL = enable
    ? ServiceConstants.ENABLE_SCHEDULER : ServiceConstants.DISABLE_SCHEDULER;

  const eventsURL = enable
    ? ServiceConstants.ENABLE_EVENTS : ServiceConstants.DISABLE_EVENTS;

  const toggleURL = events ? eventsURL : serviceURL;

  const data = await client.request(toggleURL, { channel_id: channelId })
    .then((result) => result);

  return data;
};

export default updateScheduler;
