import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { COLORS } from '../../constants/styleConstants';
import getBadges from '../../services/getBadges';
import getLocations from '../../services/getLocations';
import getShopChannelAttributes from '../../services/getShopChannelAttributes';
import titleCase from '../../utils.js';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 26,
    width: '100%',
  },
  sectionContainer: {
    border: 'solid 0.5px #dde0e6',
    borderRadius: '14px',
    boxShadow: '0 2px 4px 0 rgba(217, 217, 217, 0.5)',
    minHeight: '235px',
    width: '100%',
    display: 'flex',
    padding: '19px 16px 10px 15px',
  },
  badge: {
    height: '35px',
    width: '35px',
  },
  badgesRow: {
    justifyContent: 'space-around',
    width: 'auto',
    gridGap: '20px',
    [theme.breakpoints.up('md')]: {
      minWidth: '66.6%',
    },
    color: COLORS.caribbeanGreen,
  },
  borderBottom: {
    borderBottom: 'solid 1px #f1f1f1',
  },
  leftColumn: {
    minWidth: '33%',
  },
  grid: {
    overflow: 'auto',
  },
  check: {
    fontSize: 35,
    color: COLORS.caribbeanGreen,
  },
  unSelected: {
    fontSize: 35,
    color: '#f1f1f1',
  },
}));

const BadgesSection = ({ channelId, retailerRelationship }) => {
  const classes = useStyles();
  const [badges, setBadges] = useState([]);
  const [locations, setLocations] = useState([]);
  const [shopChannelAttributes, setShopChannelAttributes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (channelId) {
        const { badges: badgesData } = await getBadges(channelId);
        const { shops: locationsData } = await getLocations(channelId);
        const {
          shop_channel_attributes: shopChannelAttributesData,
        } = await getShopChannelAttributes(channelId);
        setBadges([...badgesData]);
        setLocations(locationsData);
        setShopChannelAttributes(shopChannelAttributesData);
        setLoading(false);
      }
    })();
  }, [channelId]);

  return (
    <>
      {badges && badges.length > 0

        && (
        <div className={classes.root}>
          <Typography variant="h2" gutterBottom>
            Badges
          </Typography>
          {loading && (
          <Box display="flex" m={2} justifyContent="center">
            <CircularProgress />
          </Box>
          )}
          {!loading && (Boolean(locations.length) || Boolean(badges.length)) && (
          <div className={classes.sectionContainer}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              className={classes.grid}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.borderBottom}
                wrap="nowrap"
              >
                <Grid item xs={4} className={classes.leftColumn}>
                  <Typography variant="subtitle2" gutterBottom>
                    {`${locations.length} Locations`}
                  </Typography>
                </Grid>
                <Grid
                  container
                  className={classes.badgesRow}
                  justifyContent="space-around"
                  alignItems="center"
                  direction="row"
                  wrap="nowrap"
                >
                  {badges.map((badge) => (
                    <Grid item>
                      <Tooltip title={badge.name}>
                        <img
                          src={badge.image_url}
                          alt={badge.name}
                          className={classes.badge}
                        />
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {!locations.length && (
              <Box display="flex" m={2} justifyContent="center">
                <Typography variant="subtitle2">{`Badging is only available for an approved ${titleCase(retailerRelationship)}`}</Typography>
              </Box>
              )}
              {locations.map((location) => (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid item xs={4} className={classes.leftColumn}>
                    <Typography variant="subtitle2" gutterBottom>
                      <Box fontWeight="600">{location.name}</Box>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    className={classes.badgesRow}
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    wrap="nowrap"
                  >
                    {badges.map((badge) => {
                      const isSelected = Boolean(
                        shopChannelAttributes[location.shop_key]?.find(
                          ({
                            attrib_name: name,
                            attrib_type: type,
                            attrib_value: value,
                          }) => name === badge.tag
                          && ['channel_location_tag', 'shop_tag'].includes(type)
                          && value,
                        ),
                      );

                      return (
                        <Grid item>
                          {isSelected && (
                            <CheckCircleIcon
                              className={classes.check}
                            />
                          )}
                          {!isSelected && (
                            <Brightness1Icon
                              className={classes.unSelected}
                            />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
          )}
        </div>
        )}
    </>
  );
};

BadgesSection.propTypes = {
  channelId: PropTypes.string.isRequired,
  retailerRelationship: PropTypes.string.isRequired,
};

export default BadgesSection;
