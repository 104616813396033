import React from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Grid from '../grid/Grid';
import { COLORS, FONT_SIZE, FONT_STYLE } from '../../constants/styleConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 26,
    width: '100%',
  },
  gridContainer: {
    marginTop: 25,
    width: '100%',
  },
  gridHeaderLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_12),
    fontWeight: FONT_STYLE.bold,
    color: COLORS.black,
  },
  gridHeadingWrapper: {
    textAlign: 'center',
  },
  liveDataCompensationRuleTypeRowLabel: {
    fontSize: theme.typography.pxToRem(FONT_SIZE.font_14),
    color: COLORS.black,
    padding: '3px 23px',
    display: 'inline-block',
    whiteSpace: 'break-spaces',
  },
  gridRowLabelWrapper: {
    textAlign: 'center',
  },
}));

const LiveData = (props) => {
  const classes = useStyles();
  const { data, locations, liveDataPos } = props;
  const noWrap = true;

  const gridColumns = [
    {
      label: `POS integration: ${liveDataPos}`,
      labelClassName: classes.gridHeaderLabel,
      width: '0.8fr',
    },
    {
      label: 'Quantity on hand',
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
    {
      label: 'Last upload',
      labelClassName: classes.gridHeaderLabel,
      wrapperClassName: classes.gridHeadingWrapper,
      width: '0.5fr',
    },
  ];

  const getLocation = (shopKey) => locations.find((item) => item.shop_key === shopKey);

  const convertToGridDataFormat = (liveDataShops) => liveDataShops.map(
    (item) => ([
      {
        label: getLocation(item?.shop_key)?.name,
        labelClassName: classes.liveDataCompensationRuleTypeRowLabel,
      },
      {
        label: `${item.on_hand_qty}`,
        labelClassName: classes.liveDataCompensationRuleTypeRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
      {
        label: item.created ? dayjs(item.created).format('MM/DD/YYYY') : '',
        labelClassName: classes.liveDataCompensationRuleTypeRowLabel,
        wrapperClassName: classes.gridRowLabelWrapper,
      },
    ]),
  );

  return (
    <>
      <Box className={classes.root}>
        <Typography
          noWrap={noWrap}
          variant="h2"
        >
          Live Data
        </Typography>
      </Box>
      <Box className={classes.gridContainer}>
        <Grid columns={gridColumns} data={convertToGridDataFormat(data)} />
      </Box>
    </>
  );
};

LiveData.propTypes = {
  data: PropTypes.string.isRequired,
  locations: PropTypes.string.isRequired,
  liveDataPos: PropTypes.string.isRequired,
};

export default LiveData;
