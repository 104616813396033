import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CrossIcon = () => (
  <SvgIcon width="15" height="15" viewBox="0 0 15 15">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g transform="translate(-512.000000, -737.000000) translate(197.000000, 398.000000) translate(280.000000, 0.000000) translate(35.000000, 313.000000) translate(0.000000, 26.000000)">
                <circle cx="7.5" cy="7.5" r="7.5" fill="#FF4158" />
                <path stroke="#FFF" d="M5 10.294L10.524 5" />
                <path stroke="#FFF" d="M5 10.294L10.524 5" transform="translate(7.762148, 7.647059) scale(-1, 1) translate(-7.762148, -7.647059)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

export default CrossIcon;
