import client from './getClient';
import ServiceConstants from '../constants/ServiceConstants';

const getChannelDocuments = async (channelUUID) => {
  const data = await client.request(ServiceConstants.GET_CHANNEL_DOCUMENTS, {
    channel_uuid: channelUUID,
  });

  return data;
};
export default getChannelDocuments;
