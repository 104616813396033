import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

const { href } = document.location;

let environment;
if (href.indexOf('localhost') >= 0) {
  environment = 'local';
}

if (environment !== 'local') {
  Sentry.init({
    dsn: 'https://19395be2ecd75c74325d2fedd0ce6fe7@o555946.ingest.us.sentry.io/4507447672438784',
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    environment: process.env.SENTRY_ENV,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
