/* eslint-disable import/prefer-default-export */
export const COLORS = {
  doctorWhite: '#f9f9f9',
  gallery: '#efefef',
  theme_primary: '#1987ff',
  theme_secondary: '#000000',
  heading: '#000000',
  sub_heading: '#333333',
  alabaster: '#fcfcfc',
  shuttleGray: '#626b7a',
  white: '#ffffff',
  mercury: '#e1e1e1',
  mineShaft: '#313131',
  black: '#000000',
  mischka: '#dde0e6',
  dodgerBlue: '#1987ff',
  green: '#00de16',
  dustyGray: '#999999',
  // colors for territory cards
  radicalRed: '#ff4158',
  azureRadiance: '#009aff',
  mintGreen: '#93ff8d',
  electricViolet: '#9f00ff',
  amber: '#ffbe01',
  viridianGreen: '#5b8d79',
  gullGray: '#93a5b6',
  sundance: '#cbc562',
  turquoiseBlue: '#71e6e7',
  paleOyster: '#989486',
  ebonyClay: '#2e2e2e',
  mineShaft2: '#2e2e2e',
  error: '#ff0000',
  warning: '#FFA500',
  success: '#48af4b',
  supernova: '#ffc700',
  emperor: '#4f4f4f',
  concrete: '#f3f3f3',
  fairPink: '#ffeeee',
  papayaWhip: '#ffeed5',
  polar: '#e5f9ef',
  redBerry: '#8c0000',
  indochine: '#bf6400',
  funGreen: '#025b30',
  alto: '#d8d8d8',
  scienceBlue: '#0058c8',
  zumthor: '#eef6ff',
  selago: '#EBF3FC',
  cosmos: '#ffcece',
  peach: '#ffe3bc',
  iceCold: '#b9f7d8',
  caribbeanGreen: '#00db73',
};

export const FONTS = {
  primary: 'Source Sans Pro',
};

// Font size defined in px
export const FONT_SIZE = {
  font_30: 30,
  font_24: 24,
  font_20: 20,
  font_18: 18,
  font_16: 16,
  font_15: 15,
  font_14: 14,
  font_13: 13,
  font_12: 12,
  font_10: 10.7,
  font_9: 9,
};

export const FONT_STYLE = {
  normal: 'normal',
  italic: 'italic',
  bold: 'bold',
};

export const SCREEN_SETTINGS = {
  default_viewport_width: 'lg',
};
